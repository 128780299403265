import React from "react";

import styled from "@emotion/styled";
import LinkComponent from "../../../../shared/LinkComponent";

const LinkWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;
    margin-bottom: 30px;
`;

const LiquidGasSuccess = () => {
    return (
        <>
            <p>Vielen Dank für Deine Anfrage.</p>
            <p>
                Wir haben Deine Anfrage erhalten und melden uns in Kürze bei
                Dir.
            </p>
            <LinkWrapper>
                <LinkComponent
                    button
                    color="red"
                    link={{
                        href: "/fluessiggas",
                        text: "Zurück zur Übersicht",
                    }}
                >
                    Zurück zur Übersicht
                </LinkComponent>
            </LinkWrapper>
        </>
    );
};

export default LiquidGasSuccess;
